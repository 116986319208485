export default {
    weekdays: [
        { label: 'Selecione', value: '' },
        { label: 'Domingo', value: 'Domingo' },
        { label: 'Segunda', value: 'Segunda' },
        { label: 'Terça', value: 'Terça' },
        { label: 'Quarta', value: 'Quarta' },
        { label: 'Quinta', value: 'Quinta' },
        { label: 'Sexta', value: 'Sexta' },
        { label: 'Sábado', value: 'Sábado' }
    ],
    typesAPN: [
        { label: 'Uma foto', value: 1 },
        //{ label: 'Duas fotos', value: 2 },
        //{ label: 'Foto da Cidade', value: 3 },
        { label: 'Padrão', value: 4 }
    ],
    typesB2B: [
        { label: 'Uma foto', value: 1 },
        { label: 'Duas fotos', value: 2 },
        { label: 'Padrão', value: 3 }
    ],
    typesRec: [
        //{ label: 'Sem foto', value: 'A' },
        { label: 'Com foto', value: 'B' },
    ],
    modelsRec: [
        { label: 'Afiliado 1k', value: 'afiliado1k' },
        { label: 'Afiliado 2k', value: 'afiliado2k' },
        { label: 'Safira', value: 'safira' },
        { label: 'Esmeralda', value: 'esmeralda' },
        { label: 'Rubi', value: 'rubi' },
        { label: 'Diamante', value: 'diamante' },
        { label: 'Diamante Elite', value: 'diamanteelite' },
        { label: 'Diamante Duplo', value: 'diamanteduplo' },
        { label: 'Diamante Duplo', value: 'diamantetriplo' },
        { label: 'Imperial Diamante', value: 'imperialdiamante' },
        { label: 'Imperial Diamante Duplo', value: 'imperialdiamanteduplo' },
        { label: 'Imperial Diamante Triplo', value: 'imperialdiamantetriplo' },
    ],
    getLabelModelRec: function(value)
    {
        return this.modelsRec.find(item => value === item.value )
    }
}